/* eslint-disable no-unused-vars */
import { createSlice } from '@reduxjs/toolkit';
import sum from 'lodash/sum';
import uniqBy from 'lodash/uniqBy';
// utils
import axios from '../../utils/axios';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState = {

  SalesPersonData: null,


};

const slice = createSlice({
  name: 'SalesPersonData',
  initialState,
  reducers: {
   

    addData(state, action) {
      state.SalesPersonData = action.payload;
    },

    
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  addData,
 
} = slice.actions;

// ----------------------------------------------------------------------
